<template>
  <div class="tooltip-content" data-t="deposit-bonus-tooltip">
    <h5 class="tooltip-title">{{ t('bonuses.depositTooltipTitile') }}</h5>
    <div class="tooltip-text">
      <p>{{ t('bonuses.depositTooltipCode', { code: currencyCode }) }}</p>
      <p>{{ t('bonuses.depositTooltipMax', { amount: bonusAmount }) }}</p>
      <ol v-if="rollingMultiplier">
        <li>
          {{
            t('bonuses.depositTooltipRolling', {
              wagerMultiplier: rollingMultiplier,
            })
          }}
        </li>
        <li v-if="rollingDuration">
          {{
            t('bonuses.depositTooltipRollingDuration', {
              wagerPeriod: rollingDuration,
            })
          }}
        </li>
        <li v-if="rollingRateMessage">
          {{ rollingRateMessage }}
        </li>
      </ol>
    </div>
    <NuxtI18nLink class="read-more" :to="readMoreUrl" target="_blank">
      {{ t('bonuses.readMore') }}
    </NuxtI18nLink>
  </div>
</template>

<script setup lang="ts">
import { useCurrencies } from '@st/payments/stores/currencies'
import useCryptoFormatter from '@st/i18n/composables/useCryptoFormatter'
import { useRollingData } from '../../composables/useRollingData'
import type { DepositBonus } from '../../types'

interface Props {
  bonus: DepositBonus
}

const props = defineProps<Props>()

const { t } = useI18n()
const readMoreUrl = usePublicFileUrl('publicFileBonusForDepositTerms')

const { currencies } = storeToRefs(useCurrencies())
const currencyCode = computed(
  () => currencies.value[props.bonus.bonusSettings.currencyId]?.code ?? '',
)

const { format: formatCrypto } = useCryptoFormatter({
  currency: currencyCode,
})

const bonusAmount = computed(() =>
  formatCrypto(props.bonus.bonusSettings.maxBonusAmountInBonusCurrency ?? 0),
)

const rollingData = computed(() => {
  const {
    rollingMultiplier,
    rollingPeriodInHours,
    rollingMinBetRate,
    rollingMaxBetRate,
    rollingCasinoCategoryIds,
  } = props.bonus.bonusSettings

  return {
    rollingMultiplier,
    rollingPeriodInHours,
    rollingMinBetRate,
    rollingMaxBetRate,
    rollingCasinoCategoryIds,
  }
})

const { rollingMultiplier, rollingDuration, rollingRateMessage } =
  useRollingData(rollingData)
</script>

<style scoped>
.tooltip-content {
  display: flex;
  flex-direction: column;

  max-width: 300px;
  padding: var(--spacing-150) var(--spacing-200);

  font: var(--desktop-text-sm-semibold);
}

.tooltip-title {
  margin: 0;
  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
}

.tooltip-text {
  margin-bottom: var(--spacing-150);
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);

  p {
    margin-top: var(--spacing-075);
    margin-bottom: 0;
  }

  ol {
    margin-bottom: 0;
    padding-left: var(--spacing-200);
  }
}

.read-more {
  cursor: pointer;
  color: var(--text-link);
  text-decoration: none;
}
</style>
