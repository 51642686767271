import Decimal from '@st/decimal'
import useNumberFormatter from '@st/i18n/composables/useNumberFormatter'
import { useCasinoStore } from '@st/casino/stores/useCasinoStore'

interface RollingBonusData {
  rollingMultiplier: string
  rollingPeriodInHours: number
  rollingMinBetRate: string | null
  rollingMaxBetRate: string | null
  rollingCasinoCategoryIds: number[] | null
}

export function useRollingData(bonus: Ref<RollingBonusData>) {
  const { t, locale } = useI18n()
  const { format: formatMultiplier } = useNumberFormatter()

  const rollingMultiplier = computed(() =>
    new Decimal(bonus.value.rollingMultiplier).greaterThan(0) ||
    new Decimal(bonus.value.rollingMultiplier).lessThan(0)
      ? formatMultiplier(bonus.value.rollingMultiplier)
      : '',
  )

  const rollingDuration = computed(() => {
    const timeInHours = bonus.value.rollingPeriodInHours

    if (!timeInHours) return ''

    const hours = timeInHours % 24
    const days = (timeInHours - hours) / 24

    if (hours === 0) return `${t('bonuses.days', days)}`

    if (days === 0) return `${t('bonuses.hours', hours)}`

    return `${t('bonuses.days', days)} ${t('bonuses.hours', hours)}`
  })

  const { getCategoryNameById } = useCasinoStore()
  const listFormatter = new Intl.ListFormat(locale.value, {
    style: 'long',
    type: 'conjunction',
  })

  const rollingRateMessage = computed(() => {
    let betMessage = ''
    let casinoMessage = ''

    const { rollingMinBetRate, rollingMaxBetRate } = bonus.value

    if (!rollingMinBetRate && !rollingMaxBetRate) betMessage = ''

    if (bonus.value.rollingCasinoCategoryIds?.length) {
      const filteredCategoriesNames = bonus.value.rollingCasinoCategoryIds
        .map((id) => getCategoryNameById(id) ?? '')
        .filter((name) => !!name)

      casinoMessage = filteredCategoriesNames.length
        ? t('bonuses.casinoRolling', {
            categories: listFormatter.format(filteredCategoriesNames),
          })
        : ''
    }

    if (rollingMinBetRate && !rollingMaxBetRate) {
      betMessage = t('bonuses.betRateMin2', {
        min: formatMultiplier(rollingMinBetRate),
      })
    }

    if (rollingMaxBetRate && !rollingMinBetRate) {
      betMessage = t('bonuses.betRateMax2', {
        max: formatMultiplier(rollingMaxBetRate),
      })
    }

    if (rollingMaxBetRate && rollingMinBetRate) {
      betMessage = t('bonuses.betRateRange2', {
        min: formatMultiplier(rollingMinBetRate as string),
        max: formatMultiplier(rollingMaxBetRate as string),
      })
    }

    const cases = `${casinoMessage} ${betMessage ? `, ${betMessage}` : ''}`

    return betMessage && casinoMessage
      ? t('bonuses.rollingInCases', { cases })
      : ''
  })

  return {
    rollingMultiplier,
    rollingDuration,
    rollingRateMessage,
  }
}
